export const BTN_COLOR_SCHEME = {
  'primary':
    'bg-gradient-to-b from-yellow-400 to-yellow-500 hover:from-yellow-400 hover:to-yellow-400 active:bg-green-500 active:text-white disabled:bg-secondary-200 disabled:cursor-not-allowed disabled:bg-grey-100 disabled:bg-gradient-to-b disabled:from-secondary-300 disabled:to-secondary-300 disabled:text-white active:disabled:text-white',
  'primary-loading': 'bg-green-500 hover:bg-green-500 pointer-events-none',
  'success':
    'bg-gradient-to-b from-yellow-400 to-yellow-500 hover:from-yellow-400 hover:to-yellow-400',
  'secondary':
    'bg-white border border-blue-300 text-blue-500 hover:border-blue-500 active:bg-blue-500 active:text-white',
  'secondary-loading': 'bg-blue-500 border border-blue-500 pointer-events-none',
  'danger': 'bg-red-600 hover:bg-red-500',
}
